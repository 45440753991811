<script setup>
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import MainBackground from '@/assets/images/004.jpg'
import LanguageConverter from '@/util/LanguageConverter'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const store = useStore()
const route = useRoute()
const currentPath = route.path
const meunList = store.state.menuStore.menuList.filter((item) => item.path !== currentPath)

meunList.push({ name: '관리자 메뉴', nameEn: 'ADMIN', children: [{ path: '/admin', name: '관리자 홈', nameEn: 'ADMIN HOME' }] })
</script>

<template>
  <section class="wf__sitemap">
    <article class="wf__sitemap-head">
      <ImageBackgroundVue :src="MainBackground" height="25vh"> </ImageBackgroundVue>
    </article>

    <article class="wf__sitemap-body">
      <section class="wf__sitemap-body-title-wrapper">
        <h3 class="wf__sitemap-body-title">{{ LanguageConverter({ kr: '사이트맵', en: 'Sitemaps' }) }}</h3>
        <h5 class="wf__sitemap-body-subtitle">
          {{ LanguageConverter({ kr: '연구실과 기업을 연결하는 공간', en: 'Find The Knowledge You Want' }) }}
        </h5>
      </section>

      <section class="wf__sitemap-body-content">
        <div v-for="(menu, i) in meunList" :key="i">
          <h4 class="wf__sitemap-body-content-title">{{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}</h4>

          <div class="divider"></div>

          <ul v-if="menu.children.length !== 0" class="wf__sitemap-body-content-list">
            <li class="wf__sitemap-body-content-list-item" v-for="(child, j) in menu.children" :key="j">
              <router-link :to="child.path">{{ LanguageConverter({ en: child.nameEn, kr: child.name }) }}</router-link>
            </li>
          </ul>

          <ul v-else class="wf__sitemap-body-content-list">
            <li class="wf__sitemap-body-content-list-item">
              <router-link :to="menu.path">{{ LanguageConverter({ en: menu.nameEn, kr: menu.name }) }}</router-link>
            </li>
          </ul>
        </div>
      </section>
    </article>
  </section>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.divider {
  width: 90%;
  height: 2px;
  background-color: var(--wf-gray-color);
  margin: 1rem 0;
}
.wf__sitemap-head {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}

.wf__sitemap-body {
  margin: 2rem;
}
.wf__sitemap-body-title-wrapper {
  margin: 2rem;
  text-align: center;
}
.wf__sitemap-body-title {
  font-weight: bold;
  margin: 0.5rem;
  font-size: 36px;
}
.wf__sitemap-body-subtitle {
  color: var(--wf-secondary-color);
  font-size: 20px;
}

.wf__sitemap-body-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  text-align: left;
  margin: 4rem 0;
}
.wf__sitemap-body-content-title {
  font-weight: bold;
  font-size: 20px;
}
.wf__sitemap-body-content-list-item {
  color: var(--wf-gray-color);
  padding: 0.25em 0;
}
</style>
